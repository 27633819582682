import gql from "graphql-tag";

export let createBroker = gql`
  mutation ($BrokerData: BrokerInput!, $parentBrokerId: Int) {
    createBroker(inputData: $BrokerData, parentBrokerId: $parentBrokerId) {
      id
      anchor
    }
  }
`;
export let editBroker = gql`
  mutation ($BrokerData: BrokerInput!, $id: ID!) {
    updateBroker(inputData: $BrokerData, brokerId: $id) {
      id
      anchor
    }
  }
`;
export let reviewBroker = gql`
  mutation ($BrokerData: BrokerInput!, $id: ID!) {
    reviewBroker(inputData: $BrokerData, brokerId: $id) {
      id
      anchor
    }
  }
`;
export let createAccountType = gql`
  mutation ($inputData: AccountTypeInput!, $brokerId: ID!) {
    createAccountType(inputData: $inputData, brokerId: $brokerId) {
      id
    }
  }
`;

export let updateAccountType = gql`
  mutation ($inputData: AccountTypeInput!, $accountTypeId: ID!) {
    updateAccountType(inputData: $inputData, accountTypeId: $accountTypeId) {
      id
    }
  }
`;

export let reviewAccountType = gql`
  mutation ($inputData: AccountTypeInput!, $accountTypeId: ID!) {
    reviewAccountType(inputData: $inputData, accountTypeId: $accountTypeId) {
      id
    }
  }
`;

export let lockingBroker = gql`
  mutation ($brokerId: ID!) {
    lockingBroker(brokerId: $brokerId)
  }
`;

export let deleteAccountType = gql`
  mutation ($accountTypeId: ID!) {
    deleteAccountType(accountTypeId: $accountTypeId)
  }
`;

export let reviewDeletingAccountType = gql`
  mutation ($accountTypeId: ID!, $toDelete: Boolean!) {
    reviewDeletingAccountType(
      accountTypeId: $accountTypeId
      toDelete: $toDelete
    )
  }
`;

export let restoreAccountType = gql`
  mutation ($accountTypeId: ID!) {
    restoreAccountType(accountTypeId: $accountTypeId)
  }
`;

export let rollbackBrokerLog = gql`
  mutation ($inputData: BrokerInput!, $brokerLogId: ID!) {
    rollbackBrokerLog(inputData: $inputData, brokerLogId: $brokerLogId) {
      id
    }
  }
`;

export let rollbackAccountTypeLog = gql`
  mutation ($inputData: AccountTypeInput!, $brokerLogId: ID!) {
    rollbackAccountTypeLog(inputData: $inputData, brokerLogId: $brokerLogId) {
      id
    }
  }
`;
export let allBrokerScreenshots = gql`
  mutation {
    allBrokerScreenshots {
      success
      brokers
    }
  }
`;

export let createArchiveScreenshots = gql`
  mutation {
    createArchiveScreenshots
  }
`;

export let brokerScreenshot = gql`
  mutation ($anchor: String!) {
    brokerScreenshot(anchor: $anchor) {
      result
      text_result
      url
    }
  }
`;

export let uploadScreenshot = gql`
  mutation ($file: Upload!, $broker_id: ID!) {
    uploadScreenshot(file: $file, broker_id: $broker_id) {
      result
      text_result
      url
    }
  }
`;

export let deleteScreenshot = gql`
  mutation ($broker_id: ID!) {
    deleteScreenshot(broker_id: $broker_id) {
      result
      text_result
      url
    }
  }
`;
export let updateFavicon = gql`
  mutation ($iconFile: Upload, $broker_id: ID!, $delete: Boolean) {
    updateFavicon(iconFile: $iconFile, brokerId: $broker_id, delete: $delete) {
      message
      url
    }
  }
`;
